<template>
  <div style="background: #f4f4f4; padding-top: 20px; padding-bottom: 50px">
    <div class="title-all">化石标本数据搜索</div>
    <div class="listall">
      <div class="list">
        <div class="genealogica">
          <el-form
            label-position="left"
            label-width="90px"
            :model="genealogicaFrom"
            class="demo-form-inline"
          >
            <el-form-item label="大类:" class="red">
              <el-input
                v-model="genealogicaFrom.bigclass"
                placeholder="大类"
              ></el-input>
            </el-form-item>

            <el-form-item label="纲:" class="blue">
              <el-input
                v-model="genealogicaFrom.classA"
                placeholder="纲"
              ></el-input>
            </el-form-item>
            <el-form-item label="目:" class="green">
              <el-input
                v-model="genealogicaFrom.orderA"
                placeholder="目"
              ></el-input>
            </el-form-item>
            <el-form-item label="科:" class="orange">
              <el-input
                v-model="genealogicaFrom.family"
                placeholder="科"
              ></el-input>
            </el-form-item>

            <el-form-item label="属:" class="red">
              <el-input
                v-model="genealogicaFrom.genus"
                placeholder="属"
              ></el-input>
            </el-form-item>

            <el-form-item label="属命名人:" class="green">
              <el-input
                v-model="genealogicaFrom.genusperson"
                placeholder="属命名人"
              ></el-input>
            </el-form-item>

            <el-form-item label="种:" class="blue">
              <el-input
                v-model="genealogicaFrom.species"
                placeholder="种"
              ></el-input>
            </el-form-item>

            <el-form-item label="命名人:" class="orange">
              <el-input
                v-model="genealogicaFrom.nomenclator"
                placeholder="命名人"
              ></el-input>
            </el-form-item>

            <el-form-item label="修订结果:" class="green">
              <el-input
                v-model="genealogicaFrom.revise"
                placeholder="修订结果"
              ></el-input>
            </el-form-item>

            <el-form-item label="参考文献:" class="red">
              <el-input
                v-model="genealogicaFrom.literature"
                placeholder="参考文献"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <!-- 化石标本信息 -->
      <div class="title">化石标本信息</div>
      <div class="list">
        <div class="genealogica">
          <el-form
            label-position="left"
            label-width="90px"
            :model="literatureFrom"
            class="demo-form-inline"
          >
            <el-form-item label="文献DOI:" class="red">
              <el-input
                v-model="literatureFrom.doi"
                placeholder="文献DOI"
              ></el-input>
            </el-form-item>
            <el-form-item label="文献:" class="green">
              <el-input
                v-model="literatureFrom.literature"
                placeholder="文献"
              ></el-input>
            </el-form-item>
            <el-form-item label="版本号前缀:" class="blue">
              <el-input
                v-model="literatureFrom.prefix"
                placeholder="版本号前缀"
              ></el-input>
            </el-form-item>
            <el-form-item label="版本登记号:" class="orange">
              <el-input
                v-model="literatureFrom.specimentsId"
                placeholder="版本登记号"
              ></el-input>
            </el-form-item>

            <el-form-item label="图像类型:" class="red">
              <el-input
                v-model="literatureFrom.imgmode"
                placeholder="图像类型"
              ></el-input>
            </el-form-item>

            <el-form-item label="版本收藏单位:" class="green">
              <el-input
                v-model="literatureFrom.collect"
                placeholder="版本收藏单位"
              ></el-input>
            </el-form-item>

            <el-form-item label="资助来源:" class="blue">
              <el-input
                v-model="literatureFrom.subsidize"
                placeholder="资助来源"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <!-- 地层地质信息 -->
      <div class="title">地层地质信息</div>
      <div class="list">
        <div class="genealogica">
          <el-form
            label-position="left"
            label-width="90px"
            :model="geologyFrom"
            class="demo-form-inline"
          >
            <el-form-item label="地质时代（纪）:" class="orange">
              <el-input
                v-model="geologyFrom.period"
                placeholder="地质时代（纪）"
              ></el-input>
            </el-form-item>
            <el-form-item label="代:" class="red">
              <el-input v-model="geologyFrom.era" placeholder="代"></el-input>
            </el-form-item>
            <el-form-item label="纪:" class="green">
              <el-input
                v-model="geologyFrom.period"
                placeholder="纪"
              ></el-input>
            </el-form-item>
            <el-form-item label="世:" class="blue">
              <el-input v-model="geologyFrom.epoch" placeholder="世"></el-input>
            </el-form-item>

            <el-form-item label="起始值/Ma:" class="orange">
              <el-input
                v-model="geologyFrom.ageFrom"
                placeholder="起始值/Ma"
              ></el-input>
            </el-form-item>

            <el-form-item label="终止值/Ma:" class="red">
              <el-input
                v-model="geologyFrom.ageTo"
                placeholder="起始值/Ma"
              ></el-input>
            </el-form-item>

            <el-form-item label="剖面:" class="green">
              <el-input
                v-model="geologyFrom.section"
                placeholder="剖面"
              ></el-input>
            </el-form-item>

            <el-form-item label="层位:" class="blue">
              <el-input
                v-model="geologyFrom.horizon"
                placeholder="层位"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <!-- 地层地理信息 -->
      <div class="title">地层地理信息</div>
      <div class="list">
        <div class="genealogica">
          <el-form
            label-position="left"
            label-width="90px"
            :model="geographyFrom"
            class="demo-form-inline"
          >
            <el-form-item label="产地（国家）:" class="red">
              <el-input
                v-model="geographyFrom.locality"
                placeholder="产地（国家）"
              ></el-input>
            </el-form-item>
            <el-form-item label="省、自治区:" class="green">
              <el-input
                v-model="geographyFrom.province"
                placeholder="省、自治区"
              ></el-input>
            </el-form-item>
            <el-form-item label="市、州:" class="blue">
              <el-input
                v-model="geographyFrom.city"
                placeholder="市、州"
              ></el-input>
            </el-form-item>
            <el-form-item label="县、区、旗:" class="orange">
              <el-input
                v-model="geographyFrom.county"
                placeholder="县、区、旗"
              ></el-input>
            </el-form-item>

            <el-form-item label="村:" class="red">
              <el-input
                v-model="geographyFrom.village"
                placeholder="村"
              ></el-input>
            </el-form-item>

            <el-form-item label="经度:" class="green">
              <el-input
                v-model="geographyFrom.longitude"
                placeholder="经度"
              ></el-input>
            </el-form-item>

            <el-form-item label="纬度:" class="blue">
              <el-input
                v-model="geographyFrom.latitude"
                placeholder="纬度"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <!-- 其他信息 -->
      <div class="title">其他信息</div>
      <div class="list">
        <div class="genealogica">
          <el-form
            label-position="left"
            label-width="90px"
            :model="elseFrom"
            class="demo-form-inline"
          >
            <el-form-item label="录入员:" class="red">
              <el-input
                v-model="elseFrom.input"
                placeholder="录入员"
              ></el-input>
            </el-form-item>
            <el-form-item label="校对员:" class="green">
              <el-input
                v-model="elseFrom.checkA"
                placeholder="校对员"
              ></el-input>
            </el-form-item>
            <el-form-item label="标本描述:" class="blue">
              <el-input
                v-model="elseFrom.description"
                placeholder="标本描述"
              ></el-input>
            </el-form-item>
            <el-form-item label="关键词:" class="orange">
              <el-input
                v-model="elseFrom.antistop"
                placeholder="关键词"
              ></el-input>
            </el-form-item>

            <el-form-item label="备注:" class="red">
              <el-input v-model="elseFrom.notes" placeholder="备注"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="list">
        <el-button type="primary" class="button" @click="getprops"
          >搜索</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  data() {
    return {
      genealogicaFrom: {
        bigclass: "",
        classA: "",
        orderA: "",
        family: "",
        genus: "",
        genusperson: "",
        species: "",
        nomenclator: "",
        revise: "",
        literature: "",
      },

      literatureFrom: {
        doi: "",
        literature: "",
        prefix: "",
        specimentsId: "",
        imgmode: "",
        collect: "",
        subsidize: "",
      },

      geologyFrom: {
        period: "",
        era: "",
        epoch: "",
        ageFrom: "",
        ageTo: "",
        section: "",
        horizon: "",
      },

      geographyFrom: {
        locality: "",
        province: "",
        city: "",
        county: "",
        village: "",
        longitude: "",
        latitude: "",
      },

      elseFrom: {
        input: "",
        checkA: "",
        description: "",
        antistop: "",
        notes: "",
      },
    };
  },
  methods: {
    ...mapMutations(['SET_SEARCH_LIST']),
    async getprops() {
      let option = Object.assign({},this.genealogicaFrom,this.literatureFrom,this.geologyFrom,this.geographyFrom,this.elseFrom)
      this.SET_SEARCH_LIST(option);
      return this.$router.push({
          path: '/home/allList',
          query: {
            type: 0,
          },
       });
    },
  },
};
</script>

<style lang="scss" scoped>
.listall {
  width: 73%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
}

.title,
.title-all {
  width: 100%;
  margin: 0 auto;
  font-size: 20px;
  color: #333333;
  text-align: left;
  padding: 16px 26px;
  box-sizing: border-box;
  border-bottom: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  font-weight: 800;
}

.title-all {
  font-size: 30px;
  text-align: center;
  box-shadow: none;
  width: 73%;
  border: none;
}

.list {
  width: 100%;
  padding: 42px;
  box-sizing: border-box;

  &-title {
    margin: 20px 0;
  }

  .genealogica {
    box-sizing: border-box;
    padding: 1%;
  }

  .button {
    margin: 50px auto;
    width: 30%;
    margin-left: 35%;
  }
}

.demo-form-inline {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  input {
    border-radius: 0px !important;
  }

  ::v-deep .el-form-item {
    width: 50%;
  }

  ::v-deep .el-input {
    caret-color: black;
    width: 270px;
  }
  ::v-deep .el-form-item__content {
    margin: 0 !important;
  }

  ::v-deep .el-form-item__label {
    position: relative;
    font-weight: 800;

    &::after {
      position: absolute;
      content: "";
      width: 3px;
      height: 16px;
      background-color: red;
      display: block;
      top: 12px;
      left: -10px;
    }
  }

  ::v-deep .el-select {
    width: 270px;
  }

  ::v-deep .el-form-item__label {
    width: 120px !important;
    padding: 0 5px 0 0 !important;
  }

  .el-form-item {
    width: 50%;

    // ::v-deep .el-form-item__label {
    //   &::after {
    //     display: none;
    //   }
    // }
  }

  .red {
    ::v-deep .el-form-item__label {
      &::after {
        background-color: red;
      }
    }
  }

  .blue {
    ::v-deep .el-form-item__label {
      &::after {
        background-color: blue;
      }
    }
  }

  .green {
    ::v-deep .el-form-item__label {
      &::after {
        background-color: green;
      }
    }
  }

  .orange {
    ::v-deep .el-form-item__label {
      &::after {
        background-color: orange;
      }
    }
  }

  ::v-deep .el-form-item__content {
    display: inline-block;
  }

  ::v-deep .el-form-item {
    left: 2%;
    display: flex;

    .el-form-item__content {
      width: 70%;
    }
  }
}
</style>
